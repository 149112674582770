import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Button, Ellipsis, Input, Skeleton, Tabs, Toast } from 'antd-mobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import {
  IApiResult,
  ILeftMenu,
  ILoginUser,
  IPageContent,
  IPopup,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { pageContentStore } from '../stores/page.store';
import { addPopupStore } from '../stores/popup.store';
import { PageLayout } from '../layouts/PageLayout';
import { PbFormBox } from '../components/PbFormBox';
import { PbFormBoxItem } from '../components/PbFormBoxItem';
import { MoreList } from '../components/MoreList';
import { SelectProject } from '../popups/SelectProject';
import { PerformanceRegistrationDetail } from '../popups/PerformanceRegistrationDetail';
import * as appUtil from '../utils/app.util';
import * as workApi from '../apis/work.api';
import * as nstlApi from '../apis/nstl.api';
import { decode } from 'html-entities';
import moment from 'moment';

/**
 * 설치관리 > PM검수
 * @constructor
 */
const PmInspectionPage = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 추가할 팝업 저장소를 정의함
  const [addPopup, setAddPopup] = useRecoilState<IPopup | null>(addPopupStore);

  // 목록 API 로딩 여부를 정의함
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // 선택한 프로젝트를 정의함
  const [selectedProject, setSelectedProject] = useState<{
    label: string;
    value: string;
  }>({ label: '전체', value: '' });

  // Shop명 검색 폼을 정의함
  const [searchShop, setSearchShop] = useState<string>('');

  // 선택한 탭을 정의함
  const [selectedTab, setSelectedTab] = useState<string>('');

  // 목록 데이터를 정의함
  const [listData, setListData] = useState<any[]>([]);

  // 목록 필터링 데이터를 정의함
  const [listFilterData, setListFilterData] = useState<any[]>([]);

  // 선택한 목록 페이지를 정의함
  const currentPageRef = useRef<number>(1);

  // 탭을 정의함
  const tabItem: any[] = useMemo(() => {
    return [
      { key: '', title: '전체' },
      {
        key: 'NSPC_CMPL',
        title: '검수완료',
      },
      {
        key: 'PM_NSPC_CMPL',
        title: 'PM검수완료',
      },
    ];
  }, []);

  // 작업 상태를 정의함
  const workState: any[] = useMemo(() => {
    return [
      {
        value: 'NSPC_CMPL',
        iconTitle: (
          <>
            검수
            <br />
            완료
          </>
        ),
        leading: 'leading-tight',
        backgroundColor: 'bg-indigo-500',
      },
      {
        value: 'PM_NSPC_CMPL',
        iconTitle: (
          <>
            PM
            <br />
            검수
            <br />
            완료
          </>
        ),
        leading: 'leading-none',
        backgroundColor: 'bg-gray-600',
      },
    ];
  }, []);

  // 목록을 불러옴
  const getListData = () => {
    setIsLoading(true);

    // 작업 현황 목록을 불러옴
    workApi
      .getWorkStt({
        nstlSttsCode: 'MBL_NSPC_CMPL',
        prjcCode: selectedProject.value,
        shipToCode: searchShop,
        currPageIdx: currentPageRef.current,
        pageSize: 30,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 더 이상 불러올 목록 데이터가 없으면 종료함
          if (currentPageRef.current > 1 && data.data.list.length === 0) {
            Toast.show({
              position: 'bottom',
              content: '마지막 페이지입니다.',
            });

            return;
          }

          // 목록 데이터에 적용함
          if (currentPageRef.current === 1) {
            // 첫 페이지면 목록 데이터를 그대로 적용함
            setListData(data.data.list);
          } else {
            // 첫 페이지가 아니면 목록 데이터를 추가함
            setListData((pre: any) => [...pre, ...data.data.list]);
          }
        }
      })
      .catch((error: any) => {})
      .finally(() => {
        // // 화면 위 검은 화면 저장소에 적용함
        // setOverlay({
        //   visible: false,
        //   content: '',
        // });
        setIsLoading(false);
      });
  };

  // 더보기 버튼을 클릭함
  const handleMoreListButton_onClick = () => {
    // 현재 페이지를 증가함
    currentPageRef.current += 1;

    // 목록을 불러옴
    getListData();
  };

  // 프로젝트 선택 버튼을 클릭함
  const handleProjectButton_onClick = () => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: '프로젝트 선택',
      content: (
        <SelectProject
          id={tmpId}
          callback={(event: any) => {
            // 선택한 프로젝트를 정의함
            setSelectedProject({
              label: event.prjcName,
              value: event.prjcCode,
            });
          }}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 90,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  // Shop명 입력칸에서 엔터키를 누름
  const handleSearchShopInput_onKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      // 검색 버튼을 클릭함
      handleSearchButton_onClick();
    }
  };

  // 검색 버튼을 클릭함
  const handleSearchButton_onClick = () => {
    // 현재 페이지를 초기화함
    currentPageRef.current = 1;

    // 목록을 불러옴
    getListData();
  };

  // 목록의 행을 클릭함
  const handleList_onClick = (event: any) => {
    let tmpId: string = nanoid();

    // 팝업을 추가함
    setAddPopup({
      id: tmpId,
      title: event.shipToCode || 'Shop 미지정',
      content: (
        <PerformanceRegistrationDetail
          id={tmpId}
          integrateData={true}
          selectedData={event}
          callback={(result: any) => {
            if (result === 'refresh-list') {
              currentPageRef.current = 1;

              // 목록을 불러옴
              getListData();
            }
          }}
        />
      ),
      widthSizePercent: 100,
      heightSizePercent: 90,
      position: 'bottom',
      maskClick: true,
      applyPadding: false,
    });
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 스크롤 버튼의 세로 위치를 이동함
    appUtil.moveVerticalPositionScrollButton('page', '', false);

    return () => {};
  }, []);

  // 검색 항목이 변경될 때 실행함
  useEffect(() => {
    if (loginUser.id === '') {
      return;
    }

    // 현재 페이지를 초기화함
    currentPageRef.current = 1;

    // 선택한 탭에 적용함
    // 2024.03.04 - 박재하 대리 요청으로 PM검수 화면일 때, '검수완료' 탭을 기본 선택으로 정의함
    setSelectedTab('NSPC_CMPL');

    // 목록을 불러옴
    getListData();

    return () => {};
  }, [loginUser.id, selectedProject.value]);

  // 목록 데이터, 탭이 변경될 때 실행함
  useEffect(() => {
    // 선택한 탭에 맞는 목록 데이터를 가져옴
    if (selectedTab === '') {
      // 목록 필터링 데이터에 적용함
      setListFilterData(listData);
    } else {
      setListFilterData(
        listData.filter((item: any) => item.nstlSttsCode === selectedTab),
      );
    }

    return () => {};
  }, [listData, selectedTab]);

  return (
    <PageLayout
      pageInfoBarLeftArea="PM검수"
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
    >
      {/* 검색 폼 박스 */}
      <PbFormBox label="검색">
        <div className="space-y-3">
          {/* 항목 */}
          <PbFormBoxItem label="프로젝트">
            {/* 버튼 */}
            <Button
              size="middle"
              fill="outline"
              onClick={handleProjectButton_onClick}
              className="w-full !h-11 !border-rose-500"
            >
              <span className="text-md text-gray-700 font-semibold">
                <Ellipsis direction="end" content={selectedProject.label} />
              </span>
            </Button>
          </PbFormBoxItem>

          {/* 항목 */}
          <PbFormBoxItem label="Shop명">
            <div className="flex justify-center items-center space-x-1">
              <Input
                // placeholder="Shop명을 입력하세요."
                clearable={true}
                value={searchShop}
                onChange={(value: any) => {
                  setSearchShop(value);
                }}
                onKeyDown={handleSearchShopInput_onKeyDown}
                className="ant-m-input w-2/3"
              />

              <Button
                shape="default"
                onClick={handleSearchButton_onClick}
                className="w-1/3 !bg-blue-500"
              >
                <span className="text-md text-white">검색</span>
              </Button>
            </div>
          </PbFormBoxItem>
        </div>
      </PbFormBox>

      {/* 탭 */}
      <Tabs
        onChange={setSelectedTab}
        activeKey={selectedTab}
        className="ant-m-tabs ant-m-tabs-page-sticky"
      >
        {tabItem.map((tabItem: any) => (
          <Tabs.Tab key={tabItem.key} title={tabItem.title}>
            <div className="space-y-1">
              {/* 로딩 중 */}
              {isLoading && (
                <div>
                  <Skeleton.Title animated />
                  <Skeleton.Paragraph lineCount={5} animated />
                </div>
              )}

              {!isLoading &&
                listFilterData.map((listItem: any, listIndex: number) => (
                  <div
                    key={listIndex}
                    onClick={() => handleList_onClick(listItem)}
                    className="button-bg-event py-2 flex space-x-3"
                  >
                    {/* 번호 */}
                    <div className="flex-none w-7 flex justify-center items-center">
                      <span className="text-sm text-gray-700">
                        {listIndex + 1}
                      </span>
                    </div>

                    {/* 작업상태 */}
                    <div className="flex-none flex justify-center items-center">
                      <div
                        className={[
                          'flex-none w-12 h-12 flex justify-center items-center rounded-full overflow-hidden',
                          _.find(workState, { value: listItem.nstlSttsCode })
                            ?.backgroundColor,
                        ].join(' ')}
                      >
                        <span
                          className={[
                            'text-xs text-white font-bold',
                            _.find(workState, { value: listItem.nstlSttsCode })
                              ?.leading,
                          ].join(' ')}
                        >
                          {
                            _.find(workState, { value: listItem.nstlSttsCode })
                              ?.iconTitle
                          }
                        </span>
                      </div>
                    </div>

                    <div className="grow flex justify-start items-center">
                      <div className="space-y-0.5">
                        {/* 첫번째 행 */}
                        <div className="flex justify-start items-center space-x-2">
                          {/* Shop */}
                          <span className="text-xs text-gray-700 font-semibold">
                            <Ellipsis
                              direction="end"
                              content={listItem.shipToCode || 'Shop 미지정'}
                            />
                          </span>
                        </div>

                        {/* 두번째 행 */}
                        <div className="flex justify-start items-center space-x-2">
                          {/* 주문번호 */}
                          <div className="px-1 py-0.5 flex justify-center items-center bg-white border border-gray-400 rounded leading-none">
                            <span className="text-xs text-gray-500 font-bold">
                              {listItem.dcmnScrnNmbr || ''}
                            </span>
                          </div>

                          {/* 작업기간 */}
                          <span className="text-xs text-gray-700 font-normal">
                            {moment(listItem.workCmplDate).format('YYYY.MM.DD')}
                            {/*{moment(listItem.workStrtDttm).format('YYYY.MM.DD')}{' '}*/}
                            {/*~{' '}*/}
                            {/*{moment(listItem.workEndDttm).format('YYYY.MM.DD')}*/}
                          </span>
                        </div>

                        {/* 세번째 행 */}
                        <div className="flex justify-start items-center space-x-2">
                          {/* 계약명 */}
                          <span className="text-base text-gray-900 font-medium">
                            <Ellipsis
                              direction="end"
                              content={decode(listItem.cntrName || '')}
                            />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="flex-none w-5 flex justify-center items-center">
                      {/* 아이콘 */}
                      <div className="flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-right']}
                          className="w-3 h-3 text-gray-500"
                        />
                      </div>
                    </div>
                  </div>
                ))}

              {/* 더 불러오기 */}
              {!isLoading && (
                <MoreList onClick={handleMoreListButton_onClick} />
              )}
            </div>
          </Tabs.Tab>
        ))}
      </Tabs>
    </PageLayout>
  );
};

export default PmInspectionPage;
